const API_ROUTES = {
  CONFIGS: 'v1/configs',
  BANNERS: 'v1/banners',
  OFFWORKS: 'v1/offworks',

  //Auth
  SIGN_IN: 'v1/sessions',
  LOG_OUT: 'posts/v2/users/logout',

  //User
  MY_PROFILE: 'v1/users/me',
  FORGOT_PASSWORD: 'v1/user/forgot-password',
  RESET_PASSWORD: 'v1/user/reset-password',
  UPDATE_PROFILE: 'v1/users/update_profile',
  CHANGE_PASSWORD: 'v1/users/change_password',
  OTHER_PROFILE: (userId: string) => `v1/users/${userId}`,
  USERS_STATUS: 'v1/users/status',
  USERS_LIST: 'v1/users',
  ACTIVITY_HISTORY: 'posts/v2/users/activity-logs',

  //Points
  POINTS_REWARDS: 'points/v1/rewards',
  POINTS_CATEGORY_REWARDS: 'points/v1/rewards/categories',
  POINTS_REWARD_REDEEM: (id: number) => `/points/v1/rewards/${id}/redeem`,
  POINTS_TRANSACTIONS: 'points/v1/transactions',

  //Poll
  POLL_LIST: 'poll/v2/polls',
  POLL_DETAIL: (id: string) => `poll/v2/polls/${id}`,
  POLL_SUBMIT: (id: string | number) => `poll/v2/polls/${id}/submit`,

  //Heatmap
  HEATMAP_RECORDS: 'heatmap/v2/records',
  HEATMAP_RECORD_DETAIL: (id: number) => `heatmap/v2/records/${id}`,
  HEATMAP_PROJECTS: 'heatmap/v2/projects',
  HEATMAP_TASKS: 'heatmap/v2/tasks',

  //Posts
  POSTS_INFO_DETAIL: (typeDetail: string, idPost: number) => `posts/v2/${typeDetail}/` + idPost,
  POSTS_FEELINGS: 'posts/v2/feelings',
  POSTS_STATUSES_POST: 'posts/v2/statuses',
  POSTS_STATUSES_GET: 'posts/v2.1/statuses',
  POSTS_STATUS_DETAIL: (id: number) => `posts/v2/statuses/${id}`,
  POSTS_DASHBOARD_NEWEST: 'posts/v2.2/dashboard/newest',

  //Post announcements
  POSTS_ANNOUNCEMENTS: 'posts/v2/announcements',
  POSTS_ANNOUNCEMENT_DETAIL: (id: number) => `posts/v2/announcements/${id}`,

  //Post news
  POSTS_NEWS: 'posts/v2/news',
  POSTS_NEW_DETAIL: (id: number) => `posts/v2/news/${id}`,

  //Post file
  POSTS_FILES: 'posts/v2/files',
  POSTS_FILE_DETAIL: (id: number) => `posts/v2/files/${id}`,

  //Post comment
  POSTS_COMMENTS: 'posts/v2/comments',
  POSTS_COMMENT_DETAIL: (id: number) => `posts/v2/comments/${id}`,
  POSTS_COMMENT_LIKE: (id: number) => `posts/v2/comments/${id}/like`,
  POSTS_LIKE_LIST: (id: number) => `posts/v2/posts/${id}/like/list`,
  POSTS_COMMENT_DISLIKE: (id: number) => `posts/v2/comments/${id}/dislike`,

  //Post tags
  POSTS_TAG_NEW: 'posts/v2/tags/new',
  POSTS_TAG_SUGGESTION: 'posts/v2/tags/suggestion',
  POSTS_TAG_STATUS: 'posts/v2/tags/status',

  //Post notification
  POSTS_NOTIFICATIONS: 'posts/v2/notifications',
  POSTS_NOTIFICATION_READ: (id: string) => `posts/v2/notifications/${id}/read`,
  POSTS_NOTIFICATIONS_READ_ALL: 'posts/v2/notifications/read-all',

  //Posts action
  POSTS_NEW_POST: 'posts/v2/posts/new',
  POSTS_LIKE_POST: (id: number) => `posts/v2/posts/${id}/like`,
  POSTS_DISLIKE_POST: (id: number) => `posts/v2/posts/${id}/dislike`,
  POSTS_FOLLOW_POST: (id: number) => `posts/v2/posts/${id}/follow`,
  POSTS_LIST_INTERACTIVE: (id: number, type: string) => `posts/v2/posts/${id}/${type}/list`,
  POSTS_READ: (id: number) => `posts/v2/posts/${id}/read`,
  POSTS_BOOKMARK: (id: number) => `posts/v2/posts/${id}/bookmark`,
  POSTS_UNBOOKMARK: (id: number) => `posts/v2/posts/${id}/unbookmark`,

  //Post users
  POSTS_USER_DEVICES: 'posts/v2/users/devices',
  POSTS_USER_FOLLOW: (userId: number) => `posts/v2/users/${userId}/follow`,
  POSTS_USER_UNFOLLOW: (userId: number) => `posts/v2/users/${userId}/unfollow`,
  POSTS_USER_MENTIONS: 'posts/v2/users/mentions',

  //Post statistics
  POSTS_STATISTICS_RECEIVE_POINTS: 'posts/v2/statistics/receive-points',
  POSTS_STATISTICS_NEWS: 'posts/v2/statistics/news',

  //Post Top
  POSTS_POINT_GIVEN: 'posts/v2/top/point-given',
  POSTS_HASHTAG: 'posts/v2/top/hashtag',
  POSTS_POINT_RECEIVERS: 'posts/v2/top/point-receivers',
  POSTS_GIVERS_BY_USER: 'posts/v2/top/givers-by-user',
  POSTS_RECEIVERS_BY_USER: 'posts/v2/top/receivers-by-user',

  //Portal
  PORTALS: 'v1/portals',
  PORTALS_RECENTS: 'v1/portals/recents',
  FAVORITE_PORTAL: (id: number) => `v1/portals/${id}/favorite`,
  UNFAVORITE_PORTAL: (id: number) => `v1/portals/${id}/unfavorite`,
  TRACK_USAGE: (id: number) => `v1/portals/${id}/track-usage`,

  //ChatGPT
  CONVERSATION: 'conversations',
  CONVERSATION_DETAIL: (id: string) => `conversations/${id}`,
  GPT_MESAGE: 'messages',
  GPT_MODELS: 'conversations/models',
  GPT_FEEDBACK: (msg_id: string) => `messages/${msg_id}/feedback`,
  LIMIT_CONVERSATIONS: 'conversations/is_limited',

  // TreeChart
  TREE_USERS_LIST: 'v1/users/list',
  TREE_USERS_CMS_LIST: 'v1/users/list_cms',
  APPROVER_CHART: 'v1/charts/approver',
  LIST_CHARTS: 'v1/charts',
  CHART: (id: number) => `v1/charts/${id}`,
  CHART_CATEGORIES: 'v1/chart_categories',
  DETAIL_CHART_CMS: (id: number) => `v1/charts/${id}/cms`,
  CREATE_NODE: (id: number) => `v1/charts/${id}/chart_nodes`,
  DETAIL_NODE: (chart_id: number, node_id: number) =>
    `v1/charts/${chart_id}/chart_nodes/${node_id}`,
  LIST_NODE_CHILDREN: (chart_id: number, node_id: number) =>
    `v1/charts/${chart_id}/chart_nodes/${node_id}/children`
};

export default API_ROUTES;
